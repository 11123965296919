import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'

const CategoryAll = ({ data, pageContext }) => {
  if (!pageContext.categories) {
    return <div className="content" />
  }

  return (
    <div className="page">
      <div className="container">
        <Helmet title={`${data.site.siteMetadata.blogTitle} - Categories`} />
        <Header
          siteTitle={data.site.siteMetadata.blogTitle}
          siteSubTitle={data.site.siteMetadata.subtitle}
        />
        <div className="content">
          <h1>Categories</h1>
          <ul className="categories">
            {pageContext.categories.map((category, index) => {
              return (
                <li key={index} className="icon-folder">
                  <Link to={`/blog/category/${category}`}>{category}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <Footer
        copyright={data.site.siteMetadata.copyright}
        repositoryName={data.site.siteMetadata.repositoryName}
        repositoryLink={data.site.siteMetadata.repositoryLink}
      />
    </div>
  )
}

CategoryAll.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query CategoryAllQuery {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        repositoryName
        repositoryLink
      }
    }
  }
`

export default CategoryAll
